<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Трансфер ЦОК: импорт</subpage-top-bar>
       
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col :cols="12" class="fill-height overflow-auto">
                    <v-stepper
                        v-model="transferImportOptions.step"
                        vertical
                        elevation="0"
                    >
                        <v-stepper-step
                            step="1"
                            :complete="transferImportOptions.step > 1"
                        >
                            Выберите файлы для импорта
                        </v-stepper-step>
                        <div class="v-stepper__content" :class="{unavailable: transferImportOptions.step > 1}">
                            <v-data-table
                                :headers="headers"
                                :items="transferImportOptions.items"
                                hide-default-footer
                                :items-per-page="99999"
                                class="elevation-1"
                            >
                                <template v-slot:item.selected="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.selected"
                                    ></v-simple-checkbox>
                                </template>
                            </v-data-table>
                            <v-divider />
                            <v-btn
                                v-if="transferImportOptions.step === 1"
                                color="primary"
                                :disabled="!allowImport"
                                @click.prevent.stop="transferImport"
                            >
                                Начать
                            </v-btn>
                        </div>

                        <v-stepper-step
                            step="2"
                            :complete="transferImportOptions.step > 2"
                        >
                            Ожидайте окончания процесса
                            <small>Можно покинкуть страницу и вернуться позже.</small>
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="transferImportOptions.step === 2">
                            <v-progress-linear
                                color="primary"
                                buffer-value="0"
                                :value="progressPercent"
                                stream
                            ></v-progress-linear>
                            <br />
                            <div class="text-caption"><strong>Выполнено:</strong> {{ transferImportOptions.progress.complete }} из {{ transferImportOptions.progress.total }}</div>
                        </div>
                        
                        <v-stepper-step
                            :complete="transferImportOptions.step > 3"
                            step="3"
                        >
                            Готово!
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="transferImportOptions.step === 3">
                            <v-alert type="success">
                                <p><strong>Экспорт материалов для переноса успешно выполнен!</strong></p>
                            </v-alert>

                            <v-btn
                                color="primary"
                                @click.prevent.stop="reset"
                            >
                                Начать новую операцию
                            </v-btn>
                        </div>

                    </v-stepper>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import SubpageTopBar from '@/components/template/SubpageTopBar';
import { mapGetters } from "vuex";
const PROGRESS_CHECK_INTERVAL = 10000;

export default {
    name: "TransferImport",
    components: {
        SubpageTopBar
    },
    data () {
        return {
            ready: false,
            headers: [
                { text: "Файл", value: "filename", sortable: false },
                { text: "Размер", value: "filesize", width: 1, sortable: false },
                { text: "", value: "selected", width: 1, align: "center", sortable: false }
            ],
            errors: null,
            transferImportOptions: {
                step: 1,
                items: [],
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        };
    },
    computed: {
        ...mapGetters("user", ["allowEdit"]),
        allowImport() {
            return _.size(this.getSelectedItems()) > 0;
        },
        progressPercent() {
            return this.transferImportOptions.progress.total > 0 ? Math.ceil((this.transferImportOptions.progress.complete / this.transferImportOptions.progress.total) * 100) : 0;
        }
    },
    methods: {
        async transferImport() {
            // Set options
            this.transferImportOptions.step = 2;
            this.transferImportOptions.items = this.getSelectedItems();
            this.transferImportOptions.progress.total = _.size(this.transferImportOptions.items);
            // Init import process
            await this.$store.dispatch('transfer/import', this.transferImportOptions);
            // Start status check daemon
            const progressChecker = setInterval(async () => {
                const { data } = await this.$store.dispatch('transfer/importCheck');
                this.$set(this, 'transferImportOptions', data.options);
                if(this.transferImportOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL)
        },
        async reset() {
            const items = await this.getAllItems();
            this.transferImportOptions = {
                step: 1,
                items: items,
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        },
        async updateTransferImportOptions() {
            const { data } = await this.$store.dispatch('transfer/importCheck');
            if(!_.isNil(data))
                this.$set(this, 'transferImportOptions', data.options);
        },
        createProgressChecker() {
            const progressChecker = setInterval(async () => {
                await this.updateTransferImportOptions();
                if(this.transferImportOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL);
        },
        async getAllItems() {
            const allTransferItems = await this.$store.dispatch('transfer/list');

            return _.map(allTransferItems.data, o =>{
                return {
                    filename: o.filename,
                    filesize: o.filesize,
                    selected: false
                }
            });
        },
        getSelectedItems() {
            return  _.filter(this.transferImportOptions.items, o => {
                return o.selected === true;
            });
        },
        resetError () {
            this.errors = null;
        }
    },
    async created () {
        try {
            if (!this.allowEdit) {
                throw new Error('У вас недостаточно прав для доступа к этому разделу.');
            }
            // Fetch data 
            const items = await this.getAllItems();
            this.transferImportOptions.items = items;
            
            // Fetch last transfer import
            await this.updateTransferImportOptions();        
            if(this.transferImportOptions.step === 2)
                this.createProgressChecker();

        } catch (e) {
            console.error(e);
            this.errors = e.message;
        } finally {
            this.ready = true;
        }
    }
};
</script>